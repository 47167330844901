@import 'ez-styles/assets/css/settings/_core_settings.scss';
@import 'ez-styles/assets/css/_reset.scss';

// Main Fonts
body,
div,
p,
li,
h1,
h2,
h3,
h4,
h5,
h6,
table,
tr,
td,
th,
a:link,
a:visited {
  font-family: $ez-font-stack !important;
}

body {
  background: rgb(243, 244, 245);
}

a {
  text-decoration: initial;
  color: $ez-color--brand__vecteezy;
}

body {
  background-color: $ez-color--grey-5;
}

// a11y Styles
.a11y-link {
  color: $ez-color--grey-100;
  text-decoration: underline;

  &:hover {
    opacity: 0.7;
    cursor: pointer;
  }
}

// Animations
.fade-in {
  animation: fadeIn 0.25s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
